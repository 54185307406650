import { graphql } from "gatsby"
import React from "react"
import appStore from "../../../images/download_on_the_app_store.png"
import googlePlay from "../../../images/get_it_on_google_play.png"
import { slugify } from "../../../utils"
import ExternalLink from "../../atoms/ExternalLink"
import BgColor from "../../layouts/BgColor"
import * as styles from "./DatoApplicationDownload.module.scss"

const DatoApplicationDownload = (props: Queries.DatoApplicationDownloadFragment) => {
  //console.log('Rendering DatoApplicationDownload', props)
  return (
    <BgColor id={slugify(props.title)} color={props.textBackground ?? undefined} className={styles.container}>
      <div className="layout-container">
        <h2>{props.title}</h2>
        {props.appStoreUrl && (
          <ExternalLink className={styles.storeLink} href={props.appStoreUrl}>
            <img src={appStore} alt="Download on the App Store" />
          </ExternalLink>
        )}
        {props.googlePlayUrl && (
          <ExternalLink className={styles.storeLink} href={props.googlePlayUrl}>
            <img src={googlePlay} alt="Get it on Google Play" />
          </ExternalLink>
        )}
      </div>
    </BgColor>
  )
}

export default DatoApplicationDownload

export const query = graphql`
  fragment DatoApplicationDownload on DatoCmsApplicationDownload {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    appStoreUrl
    googlePlayUrl
    textBackground
  }
`
